



































import Vue, { PropType } from 'vue'
import { faTh, faList } from '@fortawesome/free-solid-svg-icons'
import { ViewType } from '~/models/shared/types'
export default Vue.extend({
  props: {
    viewType: {
      type: String as PropType<ViewType>,
      default: ViewType.LIST
    }
  },
  computed: {
    icons() {
      return {
        gallery: faTh,
        list: faList
      }
    }
  },
  methods: {
    changeViewType(viewType: ViewType) {
      if (viewType === this.viewType) {
        return
      }
      this.recordAnalyticsEvent()
      this.$analytics.recordEvent({
        namespace: 'n_classifieds_search_toggle_view_type',
        action: 'click'
      })
      this.$emit('change', viewType)
    },
    toggleViewType() {
      this.recordAnalyticsEvent()
      if (this.viewType === ViewType.GALLERY) {
        this.$emit('change', ViewType.LIST)
      } else {
        this.$emit('change', ViewType.GALLERY)
      }
    },
    recordAnalyticsEvent() {
      this.$analytics.recordEvent({
        namespace: 'n_classifieds_search_toggle_view_type',
        action: 'click'
      })
    }
  }
})
