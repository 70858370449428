




































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SEARCH_SUBSCRIPTION_NS } from '~/store/modules/shared/parking/searchSubscriptions/state'
import SaveSearchButton from './SaveSearchButton.vue'
import { APP_NS } from '~/store/modules/shared/app/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import SearchSubscriptionService from '~/services/notification/search-subscription/SearchSubscriptionService'
import NotificationService from '~/services/notification/NotificationService'
import { USER_NS } from '~/store/modules/shared/user/state'
import UserAgentService from '~/services/UserAgentService'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

export default Vue.extend({
  components: {
    SaveSearchButton,
    SearchNotificationButton: () =>
      import(
        '~/components/car/classifieds/search/facets/search-saving/SearchNotificationButton.vue'
      )
  },
  async fetch() {
    if (!this.isFavorite) {
      return
    }
    await this.loadSubscriptionOfSearchPage()
  },
  data(): { subscriptionLoading: boolean } {
    return { subscriptionLoading: false }
  },
  showMobileFacets: state => state.showMobileFacets,
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, [
      'isFavorite',
      'categoryIds',
      'searchId',
      'notifications',
      'showMobileFacets'
    ]),
    ...mapGetters(APP_NS, {
      isCar: 'isCar'
    }),
    ...mapState(SEARCH_SUBSCRIPTION_NS, ['subscriptionInfoSets']),
    ...mapGetters(SEARCH_SUBSCRIPTION_NS, [
      'getSubscriptionBySearchId',
      'searchPageSubscriptionInfo'
    ]),
    ...mapDeps({
      searchSubscriptionService: SearchSubscriptionService,
      userAgentService: UserAgentService,
      dealerSiteService: DealerSiteService
    }),
    ...mapGetters(USER_NS, {
      isAnon: 'isAnon'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      currentCategoryId: 'getCurrentCategoryId'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    someSearchPagePushTypeIsValid(): boolean {
      const { bulkValid, instantValid } = this.notifications
      return (
        bulkValid ||
        instantValid ||
        this.searchPageSubscriptionInfo?.notifications.bulkValid ||
        this.searchPageSubscriptionInfo?.notifications.instantValid
      )
    },
    searchNotificationButtonShown(): boolean {
      if (
        this.isFavorite &&
        this.isAnon &&
        !this.userAgentService.isIos() &&
        !this.dealerSiteService.routeIsOfDealerSite()
      ) {
        return true
      }

      return (
        this.someSearchPagePushTypeIsValid &&
        this.searchSubscriptionService.showSearchNotificationUi(
          this.searchId,
          this.isFavorite
        )
      )
    },
    icons() {
      return {
        close: faTimes
      }
    }
  },
  watch: {
    async searchId(value: string, previousValue: string) {
      await this.loadSubscriptionOnChange(value, previousValue)
    },
    async isFavorite(value: boolean, previousValue: boolean) {
      const show = this.$refs.notificationBel?.$children?.[0]?.show
      if (
        value &&
        value !== previousValue &&
        show &&
        this.searchNotificationButtonShown
      ) {
        await this.$nextTick()
        show()
      }

      await Promise.all([
        this.loadInfoOnFavorite(value, previousValue),
        this.loadSubscriptionOnChange(value, previousValue)
      ])
    }
  },
  mounted() {
    this.loadCount()
  },
  methods: {
    ...mapActions(SEARCH_SUBSCRIPTION_NS, [
      'loadSubscriptionInfo',
      'loadCount'
    ]),
    ...mapActions(CLASSIFIED_SEARCH_NS, ['loadSubscriptionOfSearchPage']),
    async loadSubscriptionOnChange(
      value: string | boolean,
      previousValue: string | boolean
    ) {
      if (value === previousValue || this.subscriptionLoading) {
        return
      }

      this.subscriptionLoading = true
      try {
        await this.loadSubscriptionOfSearchPage()
      } finally {
        this.subscriptionLoading = false
      }
    },
    async loadInfoOnFavorite(value: boolean, previousValue: boolean) {
      const { subscriptionInfoSets, searchId } = this
      const { bulkValid, instantValid } = this.notifications
      const notificationService = this.$dep(NotificationService)

      const addedToFavorites = value && !previousValue
      if (
        !addedToFavorites ||
        typeof bulkValid !== 'undefined' ||
        typeof instantValid !== 'undefined' ||
        subscriptionInfoSets.has(searchId) ||
        !notificationService.userIsEligibleForNotifications()
      ) {
        return
      }

      await this.loadSubscriptionInfo(searchId)
    },
    handleClosePopover() {
      const hide = this.$refs.notificationBel?.$children?.[0]?.hide
      if (hide) {
        hide()
      }
    }
  }
})
