










































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api'
import { usePagination } from '~/compositions/pagination'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { Position } from '~/models/app/position'
import {
  PaginationAppearance,
  PaginationItem,
  PaginationNumericItem
} from '~/models/pagination'
import { formatNumber } from '~/utils/number'
import CLink from '~/components/shared/configurable/link/CLink.vue'

export default defineComponent({
  model: {
    prop: 'page',
    event: 'change'
  },
  props: {
    pagesCount: {
      type: Number,
      default: null
    },
    page: {
      type: [Number, String],
      default: 1
    },
    siblingCount: {
      type: Number,
      default: 1
    },
    siblingOffset: {
      type: Number,
      default: 2
    },
    boundaryCount: {
      type: Number,
      default: 1
    },
    showCounts: {
      type: Boolean,
      default: false
    },
    countsPosition: {
      type: String as PropType<Position>,
      default: Position.LEFT
    },
    nav: {
      type: Boolean,
      default: false
    },
    navLinksOnly: {
      type: Boolean,
      default: false
    },
    linkGen: {
      type: Function,
      default(pg: number) {
        const route = useRoute()
        return { query: { ...route.value.query, ...{ pg } } }
      }
    },
    perPage: {
      type: Number,
      default: null
    },
    totalResults: {
      type: [Number, String],
      default: null
    },
    resultsText: {
      type: String,
      default() {
        return this.$t('results')
          .toString()
          .toLowerCase()
      }
    },
    showRangeCounts: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appearance: {
      type: String as PropType<PaginationAppearance>,
      default: PaginationAppearance.BUTTONS
    },
    rel: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const {
      page,
      pagesCount,
      boundaryCount,
      siblingCount,
      countsPosition,
      showCounts,
      totalResults,
      perPage,
      nav,
      linkGen,
      disabled,
      siblingOffset,
      navLinksOnly,
      appearance
    } = toRefs(props)

    const internalPagesCount = computed(() => {
      if (pagesCount.value) {
        return pagesCount.value
      }
      if (totalResults.value && perPage.value) {
        return Math.ceil(totalResults.value / perPage.value)
      }
      return 1
    })
    const internalPage = ref(Number(page.value))
    watch(
      page,
      (newPageProp: number) => (internalPage.value = Number(newPageProp))
    )

    const {
      items,
      canGoToPreviousPage,
      canGoToNextPage,
      previousItemClasses,
      nextItemClasses,
      getNumericItemClasses,
      ellipsisClasses
    } = usePagination(
      internalPage,
      internalPagesCount,
      boundaryCount,
      siblingCount,
      siblingOffset,
      disabled,
      appearance
    )
    const router = useRouter()

    const containerClasses = computed(() => {
      const countsPositionClasses = () => {
        switch (countsPosition.value) {
          case Position.RIGHT: {
            return ['xl:tw-flex-row-reverse', 'xl:tw-justify-end']
          }
          case Position.LEFT:
          default: {
            return []
          }
        }
      }
      return [...countsPositionClasses()]
    })

    function handleItemClick(item: PaginationNumericItem, e: Event) {
      if (navLinksOnly.value) {
        e.preventDefault()
      }
      if (internalPage.value === item) return
      internalPage.value = item
      emit('change', internalPage.value)
    }

    function goToNextPage(e: Event) {
      if (internalPage.value + 1 > internalPagesCount.value) {
        return
      }
      if (navLinksOnly.value) {
        e.preventDefault()
      }
      internalPage.value += 1
      emit('change', internalPage.value)
    }
    function goToPreviousPage(e: Event) {
      if (internalPage.value - 1 <= 0) {
        return
      }
      if (navLinksOnly.value) {
        e.preventDefault()
      }
      internalPage.value -= 1
      emit('change', internalPage.value)
    }

    const icons = {
      chevronRight: faChevronRight,
      chevronLeft: faChevronLeft
    }

    const shouldShowCounts = computed(() => {
      return showCounts.value && totalResults.value
    })

    const rangeCountFrom = computed(
      () => (internalPage.value - 1) * perPage.value + 1
    )
    const rangeCountTo = computed(() => {
      const to = internalPage.value * perPage.value
      if (to > totalResults.value) {
        return totalResults.value
      }
      return to
    })

    function getItemComponent(item: PaginationItem) {
      if (!nav.value) {
        return 'button'
      }
      if (navLinksOnly.value) {
        return 'a'
      }
      switch (item) {
        case 'previous': {
          return canGoToPreviousPage.value ? CLink : 'button'
        }
        case 'next': {
          return canGoToNextPage.value ? CLink : 'button'
        }
        default: {
          return CLink
        }
      }
    }

    function getItemLink(item: PaginationItem) {
      if (!nav.value) {
        return null
      }
      switch (item) {
        case 'previous': {
          return canGoToPreviousPage.value
            ? router.resolve(linkGen.value(internalPage.value - 1)).href
            : null
        }
        case 'next': {
          return canGoToNextPage.value
            ? router.resolve(linkGen.value(internalPage.value + 1)).href
            : null
        }
        default: {
          return router.resolve(linkGen.value(item)).href
        }
      }
    }

    function getItemAttrs(item: PaginationItem) {
      if (nav.value) {
        if (navLinksOnly.value) {
          return {
            href: getItemLink(item)
          }
        }
        return {
          to: getItemLink(item)
        }
      }
      return {}
    }

    return {
      rangeCountFrom,
      rangeCountTo,
      icons,
      items,
      handleItemClick,
      getNumericItemClasses,
      goToNextPage,
      goToPreviousPage,
      canGoToNextPage,
      canGoToPreviousPage,
      nextItemClasses,
      containerClasses,
      shouldShowCounts,
      formatNumber,
      internalPage,
      getItemComponent,
      previousItemClasses,
      getItemAttrs,
      ellipsisClasses
    }
  }
})
