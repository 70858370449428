var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDropdown',{ref:"dDown",attrs:{"auto-hide":false,"triggers":[],"no-padding":!_vm.resetButton,"popper-class":!_vm.resetButton ? 'datepicker-popover' : null,"no-arrow":"","skidding":_vm.skidding,"placement":_vm.placement,"delay":{ show: 0, hide: 0 }},on:{"shown":_vm.handleShown,"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('client-only',[_c('div',{staticClass:"tw-flex tw-min-w-[15.75rem] tw-min-h-[16.6rem]"},[_c('CCalendar',{ref:"datepickerTemplateRef",staticClass:"c-datepicker",attrs:{"disabled":"","min-date":_vm.min,"max-date":_vm.max,"locale":_vm.locale,"disabled-dates":_vm.disabledDates,"available-dates":_vm.availableDates,"is-range":_vm.range},on:{"input":_vm.handleDateInput,"show":_vm.handlePickerShow},model:{value:(_vm.internalDate),callback:function ($$v) {_vm.internalDate=$$v},expression:"internalDate"}}),_vm._v(" "),(!_vm.isVCalendarMounted)?_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full"},[_c('CSpinner')],1):_vm._e()],1),_vm._v(" "),(_vm.resetButton && _vm.isVCalendarMounted)?_c('div',{staticClass:"tw-flex tw-justify-end tw-w-full tw-mt-2"},[_c('CButton',{staticClass:"tw-mr-[0.1875rem]",attrs:{"disabled":!_vm.internalDate,"variant":"outline-primary","attribute-value":"reset","size":"sm"},on:{"click":_vm.handleClickReset}},[_vm._v("\n          "+_vm._s(_vm.resetButtonLabel || _vm.$t('clear'))+"\n        ")])],1):_vm._e()])]},proxy:true}])},[_c('div',{staticClass:"tw-form-input tw-block tw-items-center tw-cursor-pointer tw-whitespace-nowrap tw-truncate tw-w-full tw-transition-colors tw-border-solid focus:tw-ring-primary-400 focus:tw-border-primary-400 tw-border-grey-300 tw-text-[#495057] tw-py-[0.375rem] tw-rounded-md c-datepicker",class:[
      _vm.sizeClasses,
      {
        'tw-bg-grey-100 tw-text-grey-500 tw-border-grey-300 !tw-cursor-not-allowed tw-border-solid': _vm.disabled,
        'tw-border-red': _vm.state === false,
        'tw-border-green': _vm.state
      },
      { '!tw-rounded-lg !tw-border-grey-400': _vm.isQuickSearch },
      _vm.inputClass
    ],on:{"click":_vm.handleInputClick}},[(!_vm.withoutIcon)?_c('fa',{staticClass:"tw-mr-1",attrs:{"icon":_vm.calendarIcon}}):_vm._e(),_vm._v(" "),(
        !_vm.internalDate ||
          (Array.isArray(_vm.internalDate) && _vm.internalDate.length === 0)
      )?[_vm._v("\n      "+_vm._s(_vm.placeholder || _vm.$t('select a date'))+"\n    ")]:[_vm._v("\n      "+_vm._s(_vm.formatedTextDate)+"\n    ")]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }