































































































import { SearchLink } from '~/models/search/types'
import {
  faStar,
  faTimesCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import CCount from '~/components/shared/configurable/CCount.vue'
import { mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { APP_NS } from '~/store/modules/shared/app/state'
import FeedSidebarIconContainer from '~/components/car/classifieds/index/feeds/sidebar/FeedSidebarIconContainer.vue'
import { ciSearchbarMagnifier } from '~/icons/source/regular/searchbar-magnifier'
import { ciTradesMatch } from '~/icons/source/regular/trades-match'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
export default defineComponent({
  components: { CCount, CCategoryIcon, FeedSidebarIconContainer },
  props: {
    myClassifiedsLinks: {
      type: Array as PropType<SearchLink[]>,
      required: true
    },
    inSidebar: {
      type: Boolean,
      required: false,
      default: false
    },
    inSubmenu: {
      type: Boolean,
      required: false,
      default: false
    },
    inMobileMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    navigate: {
      type: Boolean,
      required: false,
      default: false
    },
    useNuxtLink: {
      type: Boolean,
      required: false,
      default: false
    },
    flex: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      icons: {
        search: ciSearchbarMagnifier,
        star: faStar,
        trash: faTrash,
        remove: faTimesCircle,
        trades: ciTradesMatch
      }
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      notMyClassifiedsUrl: 'getNotMyClassifiedsUrl'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    })
  },
  methods: {
    getGenericLinkIcon(type: string) {
      const { trash, star, search, trades } = this.icons
      switch (type) {
        case 'promoted': {
          return star
        }
        case 'deleted': {
          return trash
        }
        case 'all': {
          return search
        }
        case 'trades': {
          return trades
        }
        default: {
          return search
        }
      }
    },
    linkClicked(e: Event, link: SearchLink) {
      if (this.useNuxtLink) {
        e.preventDefault()

        if (this.navigate) {
          return this.$router.push({
            path: link.seoUrl
          })
        }

        this.$emit('nuxt-link-clicked', link.seoUrl)
      }
    }
  }
})
