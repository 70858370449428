





































































































import Vue, { PropOptions } from 'vue'

import CCustomSelectDropdownNoSearchResults from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownNoSearchResults'
import {
  DropdownDirection,
  OptionGroup
} from '~/components/shared/configurable/form/select/types'
import CCustomSelectDropdownControls from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownControls.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import CCustomSelectDropdownFooter from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownFooter.vue'
import { Option } from '~/models/shared/types'

export default Vue.extend({
  components: {
    CCustomSelectDropdownFooter,
    CCustomSelectDropdownNoSearchResults,
    COptionWithCount,
    CCustomSelectDropdownControls
  },
  props: {
    q: {
      type: String,
      required: true
    },
    showAllButton: {
      type: Boolean,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    filteredOptionGroups: {
      type: Array,
      required: true
    } as PropOptions<OptionGroup[]>,
    filteredOptions: {
      type: Array,
      required: true
    } as PropOptions<Option[]>,
    dropdownClasses: {
      type: String,
      required: true
    },
    noResultsMessage: {
      type: String,
      required: false,
      default: ''
    },
    searchable: {
      type: Boolean,
      required: true
    },
    selectedOptions: {
      type: Array,
      required: true
    } as PropOptions<Option>,
    multiSelect: {
      type: Boolean,
      required: true
    },
    internalValue: {
      type: [Array, null],
      default: () => []
    },
    allAreSelected: {
      type: Boolean,
      required: true
    },
    showDropdownFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    dropdownDirection: {
      type: String,
      required: false,
      default: DropdownDirection.Below
    }
  },
  data: () => ({
    focusedOptionValue: null
  }),
  methods: {
    optionInSelected(option) {
      return this.selectedOptions.some(
        selectedOption => selectedOption.value === option.value
      )
    },
    handleSearchInput(text) {
      this.$emit('search', text)
    },
    handleMouseLeaveOptions() {
      this.focusedOptionValue = null
    },
    handleSelect(option) {
      if (this.canDeselect && this.optionInSelected(option)) {
        this.$emit('select', null)
      } else {
        this.$emit('select', option.value)
      }
    },
    handleFocus(value) {
      this.focusedOptionValue = value
    },
    handleChange(value) {
      this.$emit('multi-select', value)
    },
    handleSelectAllChange(selectAllChecked) {
      this.$emit('select-all', selectAllChecked)
    },
    handleClearAllButtonClick() {
      this.$emit('clear-all')
    },
    handleClickOutside() {
      this.$emit('close')
    },
    handleSelectOption(value: Array<string>) {
      this.handleChange(value)
      this.$emit('close')
    },
    isOptionDisabled(option) {
      if (typeof option.disabled === 'boolean') {
        return option.disabled
      }

      return option.count <= 0 && !this.internalValue?.includes(option.value)
    }
  }
})
