







import Vue from 'vue'
import { capitalizeAllWords } from '~/utils/string'

export default Vue.extend({
  props: {
    unit: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    capitalizeAllWords
  }
})
