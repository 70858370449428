import { CategoryId } from '~/models/category/types'

export const VehicleCategoriesIdsWithSprites = Object.freeze([
  CategoryId.JEEPSUV,
  CategoryId.VANMINIBUS,
  CategoryId.FARMERPICKUP,
  CategoryId.SPORTSCAR,
  CategoryId.COMMERCIALCAR,
  CategoryId.CABRIO,
  CategoryId.COUPESPORT,
  CategoryId.COMPACT,
  CategoryId.COMBICARAVAN,
  CategoryId.LIMO,
  CategoryId.TRAILER
])
