





























import Vue from 'vue'
import SearchbarFilterSuggestionArg from '~/components/shared/classified/search/searchbar/suggestions/SearchbarFilterSuggestionArg.vue'
import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { ciLightBulbOn } from '~/icons/source/regular/lightbulb-on'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
import { ciAngleRightLight } from '~/icons/source/solid/angle-right-light'

export default Vue.extend({
  components: { SearchbarFilterSuggestionArg },
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    inGenericSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inVehiclesSearch: 'inVehiclesSearch'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      rootCategory: state => state.config.rootParams.category,
      isFiltersPage: state => state.config.settings.isFiltersPage
    }),
    ...mapDeps({
      scrollService: ScrollService
    }),
    icons() {
      return {
        lightbulb: ciLightBulbOn,
        angleRight: ciAngleRightLight
      }
    },
    suggestionCategory() {
      return this.suggestion.category?.id || this.suggestion.category
    },
    filteredArgs() {
      if (
        !this.inGenericSearch &&
        (this.inVehiclesSearch || this.isFiltersPage)
      ) {
        const args = this.suggestion.args.filter(a => {
          if (
            a.name === 'category' &&
            a.values[0] &&
            this.rootCategory.includes(a.values[0])
          ) {
            return false
          }
          return true
        })
        return args
      }
      return this.suggestion.args
    }
  },

  methods: {
    suggestionClicked(e) {
      e.preventDefault()
      e.stopPropagation()

      this.$emit('clicked')
    }
  }
})
