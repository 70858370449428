





















































































































import Vue from 'vue'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import ForMakeModelSearchHandlerMixin from '~/components/car/classifieds/search/facets/handlers/MakeModel/ForMakeModelSearchHandlerMixin.vue'
import CCollapse from '~/components/shared/configurable/collapse/CCollapse.vue'

export default Vue.extend({
  components: {
    COptionWithCount,
    MakeOptionWithLogoAndCount,
    CCollapse
  },
  mixins: [ForMakeModelSearchHandlerMixin],
  data() {
    return {
      makesVisible: true,
      modelsVisible: true
    }
  },
  methods: {
    toggleMakes() {
      this.makesVisible = !this.makesVisible
    },
    toggleModels() {
      this.modelsVisible = !this.modelsVisible
    }
  }
})
