var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({ handler: _vm.close, events: ['click'] }),expression:"{ handler: close, events: ['click'] }"}],ref:"carzillaAutocompleteInputContainer",staticClass:"tw-w-full ac-container"},[(_vm.overlay)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOverlayVisible),expression:"isOverlayVisible"}],staticClass:"tw-fixed tw-w-full tw-h-full tw-bg-grey-800/30 tw-left-0 tw-top-0 tw-z-[1001]",on:{"click":function($event){return _vm.close()}}}):_vm._e(),_vm._v(" "),_c(_vm.prependSlotProvided || _vm.appendSlotProvided ? 'CInputGroup' : 'span',{tag:"component",scopedSlots:_vm._u([(_vm.prependSlotProvided)?{key:"prepend",fn:function(){return [_vm._t("prepend",null,{"selectOpen":_vm.selectOpen})]},proxy:true}:null,(_vm.appendSlotProvided)?{key:"append",fn:function(){return [_vm._t("append",null,{"selectOpen":_vm.selectOpen})]},proxy:true}:null],null,true)},[_vm._v(" "),_c('CInput',{ref:"carzillaAutocompleteInput",staticClass:"carzilla-autocomplete-input tw-pb-px",class:{
        'select-open': _vm.selectOpen,
        'prepend-slot-provided': _vm.prependSlotProvided,
        'append-slot-provided': _vm.appendSlotProvided,
        'tw-relative !tw-z-[1002]': _vm.overlay
      },attrs:{"id":_vm.inputId,"autocomplete":"off","has-error":_vm.valid === false,"input-class":_vm.inputClass,"max":_vm.charLimit,"placeholder":_vm.placeholder,"size":_vm.size,"title":_vm.title,"disabled":_vm.disabled,"required":_vm.required,"type":"text"},on:{"focus":_vm.handleInputFocus,"input":_vm.handleInputChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.focusAdjacentSuggestion(-1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.focusAdjacentSuggestion(1)}],"blur":_vm.handleBlur},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_vm._v(" "),_vm._v(" "),_vm._t("default",[_c('portal',{attrs:{"to":"carzilla-suggestion-select-container"}},[_c('CSuggestionSelect',{ref:"cSuggestionSelect",class:{ 'tw-shadow-sm': _vm.selectShadow },style:(_vm.selectPositionalCssRules),attrs:{"focused-suggestion":_vm.focusedSuggestion,"max-height-of-select":_vm.maxHeightOfSelect,"loading":_vm.loading,"open":_vm.selectOpen,"suggestions":_vm.suggestions},on:{"select":_vm.emitSuggestionSelect,"focus":_vm.handleSuggestionFocus},scopedSlots:_vm._u([(_vm.itemSlotProvided)?{key:"item",fn:function(slotProps){return [_vm._t("item",null,null,slotProps)]}}:{key:"item",fn:function(ref){
      var suggestion = ref.suggestion;
      var focused = ref.focused;
      var last = ref.last;
return [_c('CDefaultSuggestionItem',{attrs:{"suggestion":suggestion,"focused":focused,"last":last}})]}}],null,true)})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }