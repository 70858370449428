












import Vue from 'vue'
/* eslint-disable no-unused-expressions */
export default Vue.extend({
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const absHeight = getComputedStyle(element).height.toString()

      if (element.style.removeProperty) {
        element.style.removeProperty('width')
        element.style.removeProperty('position')
        element.style.removeProperty('visibility')
        element.style.removeProperty('height')
      } else {
        element.style.removeAttribute('width')
        element.style.removeAttribute('position')
        element.style.removeAttribute('visibility')
        element.style.removeAttribute('height')
      }

      getComputedStyle(element).height

      setTimeout(() => {
        element.style.height = absHeight
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    },
    leave(element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height

      setTimeout(() => {
        element.style.height = 0
      })
    }
  }
})
