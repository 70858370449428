
























import Vue from 'vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'

const stateColors = ['greenonly', 'redonly', 'yellowonly']

export default Vue.extend({
  components: { COptionWithCount },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValue: null
    }
  },
  computed: {
    options() {
      return (this.searchables || []).map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.selectedValue = selected?.color
    },
    onClear() {
      this.emitParams([{ name: this.selectedValue, value: null }])
      this.selectedValue = null
    },
    handleValueChange(name: string) {
      const params = stateColors.map(s => {
        if (s === name) {
          return { name: s, value: 1 }
        }
        return { name: s, value: null }
      })
      this.emitParams(params)
    }
  }
})
