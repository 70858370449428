










import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Size } from '~/models/app/size'

export default defineComponent({
  components: { CBadge },
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.MD
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
