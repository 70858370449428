





































import Vue from 'vue'
export default Vue.extend({
  props: {
    link: { type: String, default: null },
    buttonLabel: { type: String, default: null },
    header: { type: String, default: null },
    subtitle: { type: String, default: null },
    icon: { type: Array, default: null },
    variant: { type: String, default: 'info' }
  }
})
