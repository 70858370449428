















































































import Vue, { PropType } from 'vue'
import CSuggestionSelect from '~/components/shared/configurable/form/autocomplete/CSuggestionSelect.vue'
import CDefaultSuggestionItem from '~/components/shared/configurable/form/autocomplete/CDefaultSuggestionItem.vue'
import { Suggestion } from '~/components/shared/configurable/form/autocomplete/Suggestion'
import { getParentPosition } from '~/utils/dom'
import { debounce } from '~/utils/function'

export default Vue.extend({
  components: {
    CSuggestionSelect,
    CDefaultSuggestionItem
  },
  props: {
    charLimit: {
      type: Number,
      default: 80
    },
    inputDebounceTime: {
      type: Number,
      default: 200
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    maxHeightOfSelect: {
      type: Number,
      default: 380
    },
    placeholder: {
      type: String,
      default: null
    },
    propInput: {
      type: String,
      default: null
    },
    selectFirstOnClose: {
      type: Boolean,
      default: false
    },
    selectShadow: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array as PropType<Suggestion[]>,
      required: true
    },
    suggestionThreshold: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: 'sm'
    },
    title: {
      type: String,
      default: null
    },
    valid: {
      type: Boolean,
      default: null
    },
    setSuggestionTextOnSelect: {
      type: Boolean,
      default: true
    },
    clearInputOnSuggestionSelect: {
      type: Boolean,
      default: false
    },
    suggestionsWithHeaders: {
      type: Boolean,
      default: false
    },
    showSuggestionsOnInputAppear: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    overlay: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSuggestionsOnBlur: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): {
    input: string
    focusedSuggestion: string | null
    selectOpen: boolean
    selectPositionalCssRules: {
      height: string | null
      left: string | null
      top: string | null
      width: string | null
    }
    selectedSuggestions?: Map<string, Suggestion>
    isOverlayVisible: boolean
  } {
    const hasSuggestions = Boolean(this.suggestions.length)
    return {
      input: this.propInput || '',
      focusedSuggestion: hasSuggestions ? this.suggestions[0].id : null,
      selectOpen: this.showSuggestionsOnInputAppear ? hasSuggestions : false,
      selectPositionalCssRules: {
        height: null,
        left: null,
        top: null,
        width: null
      },
      isOverlayVisible: false
    }
  },
  computed: {
    prependSlotProvided() {
      return this.$slots.prepend || this.$scopedSlots.prepend
    },
    appendSlotProvided() {
      return this.$slots.append || this.$scopedSlots.append
    },
    itemSlotProvided() {
      return this.$scopedSlots.item
    }
  },
  watch: {
    propInput(value) {
      this.input = value
    },
    suggestions(value) {
      const hasSuggestions = value.length > 0
      this.focusedSuggestion = hasSuggestions ? value[0].id : null
      this.selectOpen = hasSuggestions
    }
  },
  created() {
    this.handleInputChange = debounce(
      this.handleInputChange,
      this.inputDebounceTime
    )
  },
  mounted() {
    this.updateSelectPosition()
  },
  methods: {
    clearSuggestions() {
      this.focusedSuggestion = null
      this.$emit('suggestionClear')
    },
    close() {
      this.isOverlayVisible = false
      if (this.selectFirstOnClose && this.suggestions.length > 0) {
        this.emitSuggestionSelect(this.suggestions[0].id)
        return
      }

      this.selectOpen = false
    },
    handleInputChange(input: string) {
      const trimmed = input.trim()

      if (trimmed.length === this.suggestionThreshold - 1) {
        this.$emit('autocompleteStop')
      }
      if (
        trimmed.length < this.suggestionThreshold &&
        this.suggestions.length > 0
      ) {
        this.clearSuggestions()
        return
      }

      this.updateSelectPosition()
      this.$emit('inputChange', trimmed)
    },
    handleInputFocus() {
      this.updateSelectPosition()
      this.isOverlayVisible = true
      this.selectOpen = this.suggestions.length > 0
      this.$emit('focus', this.input)
    },
    handleSuggestionFocus(id: string) {
      if (!this.suggestions.some(suggestion => suggestion.id === id)) {
        return
      }
      this.focusedSuggestion = id
    },
    emitSuggestionSelect(id: string) {
      this.isOverlayVisible = false
      const suggestion = this.suggestions.find(s => s.id === id)
      if (!suggestion) {
        this.$logger.captureError(
          new Error(`Suggestion select failed: no suggestion has id ${id}`)
        )
        return
      }

      this.$emit('suggestionSelect', id)

      if (this.clearInputOnSuggestionSelect) {
        this.input = ''
      } else if (this.setSuggestionTextOnSelect) {
        this.input = suggestion.displayText
      }
    },
    handleSubmit() {
      if (this.focusedSuggestion) {
        this.emitSuggestionSelect(this.focusedSuggestion)
        return
      }

      this.$emit('inputSubmit', this.input)
    },
    /**
     * Focuses suggestion adjacent to currently focused one, by specified offset.
     */
    focusAdjacentSuggestion(offset: number) {
      const index = this.suggestions.findIndex(
        s => s.id === this.focusedSuggestion
      )
      if (index === -1) {
        return
      }

      let nextIndex = index + offset
      nextIndex = Math.min(nextIndex, this.suggestions.length - 1)
      nextIndex = Math.max(nextIndex, 0)

      if (this.suggestionsWithHeaders) {
        const newIndex = this.findSelectableIndex(nextIndex, offset)
        if (
          typeof this.suggestions[newIndex].selectable !== 'boolean' &&
          !this.suggestions[newIndex].selectable
        ) {
          nextIndex = newIndex
        }
      }

      this.scrollToChildOfSelect(nextIndex)
      this.focusedSuggestion = this.suggestions[nextIndex].id
    },
    /**
     * Return the next/previous selectable index.
     * @param {number} index
     * @param {number} offset
     */
    findSelectableIndex(index: number, offset: number): number {
      let tmpIndex = index
      if (
        !this.suggestions[tmpIndex].selectable &&
        typeof this.suggestions[tmpIndex].selectable === 'boolean' &&
        tmpIndex + offset < this.suggestions.length &&
        tmpIndex + offset > -1
      ) {
        tmpIndex += offset
      } else {
        return tmpIndex
      }
      return this.findSelectableIndex(tmpIndex, offset)
    },

    scrollToChildOfSelect(index: string) {
      const selectRef = this.$refs.cSuggestionSelect
      if (
        !selectRef ||
        !selectRef.$el ||
        !selectRef.$el.childNodes ||
        selectRef.$el.childNodes.length === 0 ||
        index < 0 ||
        index >= selectRef.$el.childNodes.length
      ) {
        this.$logger.captureError(
          new Error(`Can't scroll to child at index ${index}.`)
        )
        return
      }

      const child = selectRef.$el.childNodes[index]
      child.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest'
      })
    },
    /**
     * Positions suggestion select relative to input form field.
     */
    updateSelectPosition() {
      this.selectPositionalCssRules = getParentPosition(
        this.$refs.carzillaAutocompleteInputContainer
      ).style
    },
    handleBlur() {
      if (this.hideSuggestionsOnBlur) {
        setTimeout(() => (this.selectOpen = false), 200)
      }

      this.$emit('blur')
    }
  }
})
