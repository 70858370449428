









import Vue from 'vue'

export default Vue.extend({
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: [String, Number],
      required: true
    },
    checked: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  computed: {
    isChecked() {
      return this.checked.includes(this.value)
    }
  },
  methods: {
    handleClick() {
      if (this.isChecked) {
        this.emitValue(this.checked.filter(value => value !== this.value))
      } else {
        this.emitValue([...this.checked, ...[this.value]])
      }
    },
    emitValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
})
