





















































































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { formatNumber } from '~/utils/number'
import { resolveCategory } from '~/utils/category'
import ShowOnMapButton from '~/components/car/classifieds/search/results/ShowOnMapButton.vue'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import { LinkType } from '~/models/search/types'
import PlotButton from '~/components/car/classifieds/search/results/PlotButton.vue'
import { ciDeals } from '~/icons/source/brand/deals'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { categoriesIconsMappings } from '~/constants/category/icon'
import { APP_NS } from '~/store/modules/shared/app/state'
import ViewTypeSelector from '~/components/car/classifieds/search/results/header/ViewTypeSelector.vue'
import SaveSearchButton from '~/components/car/classifieds/search/results/header/SaveSearchButton.vue'
import ShareButton from '~/components/car/classifieds/search/results/header/ShareButton.vue'

export default Vue.extend({
  components: {
    PlotButton,
    CCategoryIcon,
    ViewTypeSelector,
    SaveSearchButton,
    ShareButton,
    ShowOnMapButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    shortTitle: {
      type: Array,
      required: false,
      default: null
    },
    longTitle: {
      type: Array,
      required: false,
      default: null
    },
    viewType: {
      type: String,
      required: true,
      default: 'list'
    },
    hasComparisonButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      slots: state => state.slots,
      settings: state => state.config.settings,
      canBeSaved: state => state.canBeSaved,
      categoryIds: state => state.categoryIds,
      mapSearchUrl: state => state.mapSearchUrl
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      totalRows: 'getTotalRows',
      inDealsSearch: 'inDealsSearch',
      inBlackFridaySearch: 'inBlackFridaySearch',
      inAllSearch: 'inAllSearch',
      inPlotSearch: 'inPlotSearch',
      inXymaSearch: 'inXymaSearch',
      inHobbySearch: 'inHobbySearch',
      showSellers: 'showSellers'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile',
      isPc: 'isPc'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot',
      isCar: 'isCar'
    }),
    totalResultsText(): string {
      const suffix = this.showSellers
        ? this.$t('sellers')
        : this.$t('classifieds')
      return `${formatNumber(this.totalRows)} ${suffix}`.toLowerCase()
    },
    categoryIcon() {
      if (this.inDealsSearch) {
        return ciDeals
      }
      return resolveCategory(categoriesIconsMappings, this.categoryIds)
    },
    searchType() {
      if (this.inBlackFridaySearch) {
        return LinkType.BLACK_FRIDAY
      }
      if (this.inDealsSearch) {
        return LinkType.DEALS
      }
      if (this.inAllSearch) {
        return LinkType.ALL
      }
      return null
    },
    hideMobileMapBtn() {
      return this.inPlotSearch || this.inXymaSearch || this.inHobbySearch
    }
  }
})
