



























import Vue, { PropOptions } from 'vue'
import { Option, Size } from '~/models/shared/types'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
  props: {
    size: {
      type: String,
      default: Size.MEDIUM,
      required: false
    } as PropOptions<Size>,
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: false,
      default() {
        return []
      }
    } as PropOptions<Option>
  },
  data() {
    return {
      icons: {
        caretDown: faCaretDown
      }
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
})
