

















import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { setUrlParam } from '~/utils/http'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CPagination from '~/components/shared/configurable/pagination/CPagination.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
export default Vue.extend({
  components: { CPagination },
  props: {
    showTotals: {
      type: Boolean,
      required: false,
      default: false
    },
    rel: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      currentPage: 'getResultsPaginationPage',
      numOfPages: 'getNumOfPages',
      totalRows: 'getTotalRows',
      showSellers: 'showSellers'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      loadingResults: state => state.loading.results,
      seoUrl: state => state.page.seoUrl
    }),
    paginationLink() {
      return pageNum => {
        const addressBarUrl = this.seoUrl || this.$router.currentRoute.fullPath
        if (addressBarUrl.includes('pg=')) {
          return addressBarUrl.replace(/([?|&]pg=)[^&]+/, '$1' + pageNum)
        } else if (addressBarUrl.includes('?')) {
          return this.seoUrl + '&pg=' + pageNum
        } else {
          return this.seoUrl + '?pg=' + pageNum
        }
      }
    },
    pg: {
      get() {
        return this.currentPage
      },
      async set(page) {
        if (page !== this.currentPage) {
          const newPageUrl = setUrlParam(
            `${window.location.pathname}${window.location.search}`,
            'pg',
            page
          )
          await this.updateUrl(newPageUrl)
          this.updateRoutes()
          await this.changePage({
            page
          })
          if (this.showSellers) {
            return this.searchForSellers()
          }
          return this.searchForResults({ loading: true, pageView: true })
        }
      }
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      searchForSellers: 'searchForSellers',
      searchForResults: 'searchForResults',
      updateUrl: 'updateUrl',
      updateRoutes: 'updateRoutes',
      changePage: 'changePage'
    })
  }
})
