





































import Vue from 'vue'
export default Vue.extend({
  props: {
    focusedSuggestion: {
      type: String,
      default: null
    },
    open: {
      type: Boolean,
      required: true
    },
    suggestions: {
      type: Array, // Suggestion[]
      required: true
    },
    maxHeightOfSelect: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapperStyle() {
      return this.maxHeightOfSelect
        ? `max-height: ${this.maxHeightOfSelect}px`
        : null
    },
    overlayStyle() {
      return this.maxHeightOfSelect
        ? `height: ${this.maxHeightOfSelect}px`
        : null
    }
  },
  watch: {
    loading() {
      const suggestionsWrapperRef = this.$refs.suggestionsWrapperRef
      if (suggestionsWrapperRef) {
        suggestionsWrapperRef.scrollTo(0, 0)
      }
    }
  },
  methods: {
    /**
     * @param {string} id
     */
    handleFocus(id) {
      this.$emit('focus', id)
    },
    /**
     * @param {String} id
     */
    handleSelect(id) {
      this.$emit('select', id)
    }
  }
})
