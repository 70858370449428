
























































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Results from '~/components/car/classifieds/search/results/Results.vue'
import CBackToTop from '~/components/shared/configurable/CBackToTop.vue'
import {
  SET_LOADING_RESULTS,
  SET_PARAMS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import Facets from '~/components/car/classifieds/search/facets/Facets.vue'
import SearchMixin from './SearchMixin.vue'
import CMobileSidebar from '~/components/shared/configurable/CMobileSidebar.vue'
import CFacetsFloatingButton from '~/components/shared/configurable/button/CFacetsFloatingButton.vue'
import CBreadcrumbs from '~/components/shared/configurable/CBreadcrumbs.vue'

export default Vue.extend({
  components: {
    CBreadcrumbs,
    MobileSideBarFooter: () =>
      import(
        '~/components/car/classifieds/search/mobile/MobileSideBarFooter.vue'
      ),
    MobileSideBarHeader: () =>
      import(
        '~/components/car/classifieds/search/mobile/MobileSideBarHeader.vue'
      ),
    CMobileSidebar,
    CFacetsFloatingButton,
    Results,
    CBackToTop,
    Facets,
    ConsentPrompt: () =>
      import('~/components/shared/consent/ConsentPrompt.vue'),
    FloatingMapButton: () => import('./map-search/FloatingMapButton.vue')
  },

  mixins: [SearchMixin],
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      breadcrumbs: state => state.page.breadcrumbs,
      showMobileFacets: state => state.showMobileFacets,
      settings: state => state.config.settings,
      mapSearchUrl: state => state.mapSearchUrl
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      facetCount: 'getFacetsCount',
      inPlotSearch: 'inPlotSearch',
      inXymaSearch: 'inXymaSearch',
      inHobbySearch: 'inHobbySearch',
      inRentalsSearch: 'inRentalsSearch'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapGetters(PAGE_NS, {
      pageIsExplicitAndRequiresConsent: 'pageIsExplicitAndRequiresConsent'
    }),
    showMobileMapBtn() {
      return (
        this.inPlotSearch ||
        this.inXymaSearch ||
        this.inHobbySearch ||
        this.inRentalsSearch
      )
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      toggleMobileFacets: 'toggleMobileFacets',
      clearAll: 'clearAll',
      search: 'search',
      recordListViewsStats: 'recordListViewsStats'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setLoadingResults: SET_LOADING_RESULTS,
      setParams: SET_PARAMS
    }),
    handleMapButtonClick() {
      let catName = ''
      if (this.inPlotSearch) {
        catName = 'plot'
      } else if (this.isXymaSearch) {
        catName = 'hobby'
      } else if (this.inHobbySearch) {
        catName = 'hobbb'
      }

      this.$analytics.recordEvent({
        namespace: 'n_search_mobile_map_button_click' + catName,
        action: 'click',
        label: catName
      })

      if (this.isMobile) {
        window.location.href = `${window.location.origin}${this.mapSearchUrl}`
      } else {
        this.$router.push({ path: this.mapSearchUrl })
      }
    }
  }
})
