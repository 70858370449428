











import Vue from 'vue'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { Corner, FloatingCornerElementName } from '~/models/layout'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REMOVE_CORNER_ELEMENT } from '~/store/modules/shared/page/mutation-types'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'

export default Vue.extend({
  data: () => ({
    icons: {
      chevronUp: faChevronUp
    },
    showBtt: false
  }),
  computed: {
    ...mapGetters(PAGE_NS, {
      renderButton: 'backToTopButtonShown'
    }),
    ...mapDeps({
      scrollService: ScrollService
    })
  },
  mounted() {
    this.addCornerElement({
      name: FloatingCornerElementName.BACK_TO_TOP_BUTTON,
      corner: Corner.BOTTOM_RIGHT,
      componentInstance: this
    })

    const scrollListener = () => {
      this.showBtt =
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
    }
    window.addEventListener('scroll', scrollListener)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', scrollListener)
    })
  },
  methods: {
    ...mapActions(PAGE_NS, ['addCornerElement']),
    ...mapMutations(PAGE_NS, { removeCornerElement: REMOVE_CORNER_ELEMENT }),
    backToTop() {
      this.$analytics.recordEvent({
        namespace: 'n_back_to_top',
        action: 'click'
      })
      this.scrollService.scrollToTop({ duration: 500, easing: 'ease-out' })
    }
  }
})
