









































import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart'
import { mapActions, mapState, mapMutations } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import SaveSearchModal from './SaveSearchModal.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import CConfirmationModal from '~/components/shared/configurable/modal/CConfirmationModal.vue'
import { SET_PARAM } from '~/store/modules/shared/classifieds/search/mutation-types'

export default defineComponent({
  components: { SaveSearchModal, CConfirmationModal },
  data: () => ({
    icons: {
      heart: faHeart,
      rHeart: farHeart
    },
    isSaveSearchModalVisible: false,
    isReplaceSavedSearchModalVisible: false
  }),
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, [
      'isFavorite',
      'searchId',
      'previousSearch'
    ])
  },
  watch: {
    isFavorite(value: boolean) {
      if (value) {
        this.setParam({ name: 'from-saved-search', value: this.searchId })
      }
    }
  },
  mounted() {
    if (this.isFavorite) {
      this.setParam({ name: 'from-saved-search', value: this.searchId })
    }
    this.$store.subscribe(mutation => {
      if (this.searchId !== mutation.payload) {
        return
      }

      if (mutation.type === 'parking/ADD_TO_SEARCHES') {
        this.performPostFavoritesActions({ isFavorite: true })
      } else if (mutation.type === 'parking/REMOVE_SEARCH') {
        this.performPostFavoritesActions({ isFavorite: false })
      }
    })
  },
  methods: {
    ...mapActions({
      addToFavorites: 'classifieds/search/addToFavorites',
      removeFromFavorites: 'classifieds/search/removeFromFavorites',
      performPostFavoritesActions:
        'classifieds/search/performPostFavoritesActions'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setParam: SET_PARAM
    }),
    async toggle() {
      if (this.isFavorite) {
        await this.removeFromFavorites()
        this.setParam({ name: 'from-saved-search', value: undefined })
      } else if (this.previousSearch) {
        this.isReplaceSavedSearchModalVisible = true
      } else {
        await this.saveAndShowModal()
        this.setParam({ name: 'from-saved-search', value: this.searchId })
      }
    },

    toggleModal(show: boolean) {
      this.isSaveSearchModalVisible = show
    },
    handleCancelConfirmation() {
      this.saveAndShowModal()
    },
    replaceSearch() {
      this.isReplaceSavedSearchModalVisible = false
      this.addToFavorites(true)
    },

    async saveAndShowModal() {
      await this.addToFavorites()
      this.toggleModal(true)
    }
  }
})
