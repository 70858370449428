import { AxiosError } from 'axios'
import { inject } from 'tsyringe'

import { containerScoped } from '~/decorators/dependency-container'
import { UrlShortenerResult } from '~/models/url'
import { toCamelCase } from '~/utils/object'
import RequestBuilder from '~/builders/http/RequestBuilder'
import SnackbarService from '~/services/snackbar/SnackbarService'
import LoggerService from '~/services/LoggerService'
import { HttpStatus } from '~/constants/http'
import VueI18n from 'vue-i18n'
@containerScoped()
export default class UrlShortenerService {
  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(LoggerService) private logger: LoggerService,
    @inject(VueI18n) private i18n: VueI18n
  ) {}

  shorten(url: string): Promise<UrlShortenerResult> {
    return this.requestBuilder
      .request('post', '/api/url-shorterer/')
      .data({ url })
      .validate(body => body)
      .map((body: any) => {
        delete body.status
        return toCamelCase(body)
      })
      .send()
  }

  expand(urlid: string): Promise<{ expanded: string }> {
    return this.requestBuilder
      .request('get', '/api/expand-short-url/')
      .params({ short_id: urlid })
      .send()
  }

  handleError(error: AxiosError) {
    const response = error.response
    if (response) {
      switch (response.status) {
        case HttpStatus.BAD_REQUEST: {
          this.snackbar.error(response.data.error)
          break
        }
        case HttpStatus.INTERNAL_SERVER_ERROR: {
          this.snackbar.error(
            this.i18n.t('an error occurred please try again later')
          )
          break
        }
        default: {
          this.snackbar.error(response.data.error)
          this.logger.captureError(error)
          break
        }
      }
    }
  }
}
