
















import { defineComponent } from '@nuxtjs/composition-api'
import { ButtonVariantType } from '~/models/app/variant'
import CButton from '~/components/shared/configurable/button/CButton.vue'
import CContainer from '~/components/shared/configurable/grid/CContainer.vue'
import { Icon } from '~/models/common/types'

export interface IActionProp {
  title?: string | number
  variant?: ButtonVariantType
  onClick?: (e: Event) => unknown
  buttonClass?: string
  icon?: Icon
  disabled?: boolean
}

export default defineComponent({
  components: {
    CContainer,
    CButton
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    ariaRole: {
      type: String,
      default: 'group'
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isQuickSearch: {
      type: Boolean,
      default: false
    }
  }
})
