






































































































import { faCaretDown, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Option, Size } from '~/models/shared/types'
import CCustomSelectDeselectAllButton from '~/components/shared/configurable/form/select/custom/CCustomSelectDeselectAllButton.vue'
import CTag from '~/components/shared/configurable/form/tag/CTag.vue'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import { mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    MakeOptionWithLogoAndCount,
    CTag,
    CCustomSelectDeselectAllButton,
    CCategoryIcon
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    headerClasses: {
      type: String,
      required: true
    },
    mainTextClasses: {
      type: String,
      default: ''
    },
    dropdownDirection: {
      type: String,
      required: false,
      default: 'below'
    },
    headerPreText: {
      type: String,
      required: false,
      default: null
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    internalDropdownOpen: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<Size>,
      required: true
    },
    canDeselectSingle: {
      type: Boolean,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false,
      default: null
    },
    state: {
      type: Boolean,
      default: null
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withMakeLogo: {
      type: Boolean,
      default: false
    },
    showSelectedTags: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isQuickSearch: state => state.isQuickSearch
    }),
    placeholderWhenShowNoTags() {
      return this.selectedOptionSet.length === 1
        ? this.selectedOptionSet[0].headerText || this.selectedOptionSet[0].text
        : `${this.selectedOptionSet.length} ${this.$i18n
            .t('selected')
            .toString()
            .toLowerCase()}`
    },
    icons() {
      return {
        caretDown: faCaretDown,
        chevronDown: faChevronDown
      }
    },
    computedClasses(): string[] {
      const { disabled } = this
      const classes = [
        ...[this.headerClasses],
        this.size,
        this.dropdownDirection,
        { 'is-invalid': this.state === false },
        { 'is-valid': this.state === true }
      ]
      if (disabled) {
        classes.push(['tw-bg-[#f5f5f5]', 'pointer-events-none'])
      } else {
        classes.push('tw-bg-white')
      }
      if (this.isQuickSearch) {
        classes.push(['tw-rounded-lg', '!tw-bg-white', '!tw-border-grey-400'])
      } else {
        classes.push('tw-rounded-md')
      }

      return classes
    },
    caretRotation() {
      return this.internalDropdownOpen ? 180 : null
    },
    selectedOptionSet(): Option[] {
      const set = new Set<string>()
      const options = []
      for (const o of this.selectedOptions) {
        const duplicateName: boolean = set.has(o.text)
        set.add(o.text)
        if (duplicateName) {
          continue
        }

        options.push(o)
      }
      return options
    },
    separatedSelectedOptions(): string {
      return this.selectedOptionSet
        .map(option => option.headerText || option.text)
        .join(', ')
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
})
