































































































































import Vue from 'vue'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import CCustomOptionGroupsSelect from '~/components/shared/configurable/form/select/custom/CCustomOptionGroupsSelect.vue'

import ForMakeModelSearchHandlerMixin from '~/components/car/classifieds/search/facets/handlers/MakeModel/ForMakeModelSearchHandlerMixin.vue'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'

export default Vue.extend({
  components: {
    CCustomSelect,
    MakeOptionWithLogoAndCount,
    CCustomOptionGroupsSelect
  },
  mixins: [ForMakeModelSearchHandlerMixin],
  props: {
    onlyRegistration: {
      type: Boolean,
      default: false
    }
  }
})
