






































import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  ref
} from '@nuxtjs/composition-api'
import { Timeout } from '~/models/timing/timeout'
import { useUserAgent } from '~/compositions/user-agent'
import { ciHeart as ciHeartRegular } from '~/icons/source/regular/heart'
import { ciHeart as ciHeartSolid } from '~/icons/source/solid/heart'

export default defineComponent({
  props: {
    saved: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { isPc } = useUserAgent()

    const popoverTimeout = ref<Timeout | null>()
    const isPopoverVisible = ref(false)

    onBeforeUnmount(() => {
      if (popoverTimeout.value) {
        clearTimeout(popoverTimeout.value)
      }
    })

    async function displayPopover() {
      const secs = 3000
      if (popoverTimeout.value) {
        clearTimeout(popoverTimeout.value)
        isPopoverVisible.value = false
      }

      await nextTick()

      popoverTimeout.value = setTimeout(() => {
        isPopoverVisible.value = false
      }, secs)
      isPopoverVisible.value = true
    }

    return {
      displayPopover,
      isPopoverVisible,
      isPc,
      ciHeartRegular,
      ciHeartSolid
    }
  }
})
