












































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  ref
} from '@nuxtjs/composition-api'
import { Timeout } from '~/models/timing/timeout'
import { useUserAgent } from '~/compositions/user-agent'
import { ciParkingP } from '~/icons/source/solid/parking-p'

export default defineComponent({
  props: {
    parked: {
      type: Boolean,
      required: true
    },
    displayText: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isPc } = useUserAgent()

    const timeout = ref<Timeout | null>()
    const isPopoverVisible = ref(false)

    const parkingButtonClasses = computed(() => {
      const classes = []

      if (props.parked && !props.loading) {
        classes.push('tw-bg-blue-700 hover:tw-bg-blue-800')
      } else {
        classes.push('tw-bg-blue-200 hover:tw-bg-blue-300')
      }

      return classes
    })

    onBeforeUnmount(() => {
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
    })

    async function displayPopover() {
      const secs = 3000

      if (timeout.value) {
        clearTimeout(timeout.value)
        isPopoverVisible.value = false
      }

      await nextTick()

      timeout.value = setTimeout(() => {
        isPopoverVisible.value = false
      }, secs)
      isPopoverVisible.value = true
    }

    return {
      isPopoverVisible,
      isPc,
      parkingButtonClasses,
      ciParkingP,
      displayPopover
    }
  }
})
