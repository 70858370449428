
















import Vue, { PropType } from 'vue'
import CAdContainer from '~/components/shared/configurable/ad/container/CAdContainer.vue'
import CAdTarget from '~/components/shared/configurable/ad/target/CAdTarget.vue'
import { AdmanAd, placeholderProp } from '~/models/ads'
import AdmanAdsService from '~/services/ads/AdmanAdsService'
import AdViewService from '~/services/ads/AdViewService'
import GoogleAdsService from '~/services/ads/GoogleAdsService'

export default Vue.extend({
  components: { CAdContainer, CAdTarget },
  props: {
    ad: {
      type: Object as PropType<AdmanAd>,
      required: true
    },
    sticky: {
      type: Boolean,
      default: false
    },
    placeholder: placeholderProp,
    lazy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      created: false
    }
  },
  computed: {
    adViewService(): AdViewService {
      return this.$dep(AdViewService)
    },
    containerSize(): [number, number] {
      const { ad, adViewService } = this
      return adViewService.getMaxSizeDimensions(
        ad.size.split('x').map(d => parseInt(d))
      )
    },
    elementId() {
      return `am-${this.ad.ws}`
    }
  },
  mounted() {
    if (!this.lazy) {
      this.createAd()
    }
  },
  beforeDestroy() {
    if (this.created) {
      this.destroyAd()
    }
  },
  deactivated() {
    if (this.created) {
      this.destroyAd()
    }
  },
  methods: {
    destroyAd() {
      if (this.ad.passbackId) {
        this.destroyPassback()
      }
      this.$nextTick(() => {
        this.$refs.am &&
          this.$refs.am.$el &&
          this.$refs.am.$el.replaceChildren &&
          this.$refs.am.$el.replaceChildren()
      })
      this.created = false
    },
    destroyPassback() {
      const googleAdsService = this.$dep(GoogleAdsService)
      const slot = googleAdsService.getSlotByAdId(this.ad.passbackId)
      googleAdsService.destroySlots([slot])
    },
    createAd() {
      const admanAdsService = this.$dep(AdmanAdsService)
      const { ws, size } = this.ad
      admanAdsService.createAd(ws, size, this.elementId)
      this.created = true
    },
    handleVisibilityChange(isVisible: boolean) {
      if (!this.created && isVisible) {
        this.createAd()
      }
    }
  }
})
