



















import Vue from 'vue'
import BaseSearchHandler from './BaseSearchHandler'
import CAutocompleteInput from '~/components/shared/configurable/form/autocomplete/CAutocompleteInput.vue'
import { Suggestion } from '~/components/shared/configurable/form/autocomplete/Suggestion'

interface PartNumber {
  name: string
  value: string
}

interface Data {
  q: string
  partNumbers: PartNumber[]
  suggestions: Suggestion[]
}

export default Vue.extend({
  components: {
    CAutocompleteInput
  },
  extends: BaseSearchHandler,
  data(): Data {
    return {
      q: '',
      partNumbers: [],
      suggestions: []
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.q = selected.value
    },
    async handleInputChange(input: string) {
      this.partNumbers = await this.getPartNumbers(input)
      this.suggestions = this.partNumbers.map(this.getSuggestionFromPartNumber)
    },
    handleSuggestionClear() {
      this.partNumbers = []
      this.suggestions = []
    },
    handleInputSubmit(input: string) {
      this.selectPartNumber(input)
    },
    handleSuggestionSelect(id) {
      const partNumber = this.partNumbers.find(
        partNumber => partNumber.name === id
      )
      this.selectPartNumber(partNumber.name)
    },
    selectPartNumber(partNumberName: string) {
      this.q = partNumberName
      this.partNumbers = []
      this.suggestions = []
      this.emitParams([{ name: this.defaultUrlArgName, value: partNumberName }])
    },
    getSuggestionFromPartNumber(partNumber): Suggestion {
      return {
        id: partNumber.name,
        displayText: partNumber.name
      }
    },
    async getPartNumbers(input: string): PartNumber[] {
      const { data } = await this.$axios.get(
        '/api/classifieds/part-number/query/',
        {
          params: {
            q: input
          }
        }
      )
      return data.data.suggestions
    }
  }
})
