





































import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { Timeout } from '~/models/timing/timeout'

export default defineComponent({
  model: {
    prop: 'visible',
    event: 'input'
  },
  props: {
    visible: { type: Boolean, default: false },
    duration: {
      type: Object as PropType<Record<'show' | 'hide', number>>,
      default: () => ({ show: 350, hide: 350 })
    },
    contentClass: { type: [Array, String, Object], default: () => [] },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { visible, duration, contentClass } = toRefs(props)
    const collapseContentTemplateRef = ref<HTMLElement | null>()
    const isExpanded = ref(visible.value)
    const maxHeight = ref(isExpanded.value ? 'initial' : '0')
    const maxHeightTimeout = ref<Timeout | null>()

    watch(visible, (newVal: boolean) => {
      isExpanded.value = newVal
    })
    watch(isExpanded, newExpandedValue => setMaxHeight(newExpandedValue))
    const getWrapperHeight = () => {
      if (!collapseContentTemplateRef.value) {
        return null
      }
      return collapseContentTemplateRef.value.scrollHeight
    }

    function handleOpenerClick() {
      isExpanded.value = !isExpanded.value

      emit('input', isExpanded.value)
      emit('change', isExpanded.value)
    }

    function expand() {
      isExpanded.value = true
    }

    async function setMaxHeight(expanded: boolean) {
      maxHeight.value = `${getWrapperHeight()}px`
      await nextTick()
      if (maxHeightTimeout.value) {
        clearTimeout(maxHeightTimeout.value)
      }
      if (expanded) {
        maxHeightTimeout.value = setTimeout(
          () => (maxHeight.value = 'initial'),
          duration.value.show + 50
        )
      } else {
        setTimeout(() => (maxHeight.value = '0'), 50)
      }
    }

    const contentClasses = computed(() => {
      return [...contentClass.value]
    })

    return {
      expand,
      isExpanded,
      handleOpenerClick,
      collapseContentTemplateRef,
      maxHeight,
      contentClasses
    }
  }
})
