














































































import { faCompressAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CompareService from '~/services/compare/CompareService'

export default Vue.extend({
  components: {
    CConfirmationModal: () =>
      import('~/components/shared/configurable/modal/CConfirmationModal.vue')
  },
  props: {
    hideMobileCounter: { type: Boolean, default: false }
  },
  data() {
    return {
      showModal: false,
      isCompareCountVisible: true,
      isActionsVisible: false,
      icons: {
        remove: faTrashAlt,
        compare: faCompressAlt
      },
      actions: []
    }
  },
  computed: {
    ...mapGetters(STORAGE_NS, {
      getItem: 'getItem'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      currentCategoryId: 'getCurrentCategoryId'
    }),
    counter(): number {
      const compare = this.getItem('compare')
      if (!compare || !this.currentCategoryId) {
        return null
      }

      return compare[this.currentCategoryId]?.length || null
    },
    formatedCounter() {
      if (this.counter > 99) {
        return '99+'
      }
      return this.counter
    },
    compareUrl(): string {
      const compareService = this.$dep(CompareService)
      return compareService.createCompareUrl(this.currentCategoryId)
    }
  },
  mounted() {
    this.actions = [
      {
        icon: 'link',
        label: this.$i18n.t('check comparison classifieds'),
        href: () => this.compareUrl,
        click: () => (this.isActionsVisible = false)
      },
      {
        icon: 'remove',
        label: this.$i18n.t('delete comparison'),
        href: null,
        click: this.showConfirmationModal
      }
    ]
  },
  methods: {
    submitButtonVisibilityChanged(isVisible: boolean) {
      this.isCompareCountVisible = isVisible
    },
    showConfirmationModal() {
      this.showModal = true
    },
    clearConfirmed() {
      this.isActionsVisible = false
      this.$emit('clear', this.currentCategoryId)
    }
  }
})
