

















import Vue, { PropOptions } from 'vue'
import { Suggestion } from './Suggestion'

export default Vue.extend({
  props: {
    focused: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      required: true
    },
    suggestion: {
      type: Object,
      required: true
    } as PropOptions<Suggestion>
  }
})
