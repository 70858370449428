


















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { useNamespacedStore } from '~/compositions/store'

export default defineComponent({
  setup() {
    const { state } = useNamespacedStore(CLASSIFIED_SEARCH_NS)
    const showTaxiPermitRentAlert = computed(() => {
      if (state.params.offer_type && state.params.offer_type.length) {
        return false
      }
      const permitCategoryIsSelected = state.params.category?.includes('15856')
      const permitAndVehicleCategoryIsSelected = state.params.category?.includes(
        '15681'
      )
      return permitCategoryIsSelected || permitAndVehicleCategoryIsSelected
    })

    return { showTaxiPermitRentAlert }
  }
})
