
















import Vue from 'vue'
import { ciFilterCategoriesV2 } from '~/icons/source/regular/filter-categories-v2'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { FloatingCornerElementName, Corner } from '~/models/layout'
import { REMOVE_CORNER_ELEMENT } from '~/store/modules/shared/page/mutation-types'
import { PAGE_NS } from '~/store/modules/shared/page/state'

export default Vue.extend({
  props: {
    analyticsEventNamespace: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    /**
     *  The type of prop is Element.
     *  Use null because the type Element
     *  is undef on component init
     */
    scrollContainer: {
      type: null,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLabel: false,
      lastScrollTop: 0
    }
  },
  computed: {
    ...mapGetters(PAGE_NS, ['bottomRightCornerElements']),
    icons: () => ({
      facets: ciFilterCategoriesV2
    })
  },
  watch: {
    lastScrollTop(val, oldVal) {
      if (val > 5) {
        this.showLabel = val <= oldVal
      } else {
        this.showLabel = true
      }
    }
  },
  mounted() {
    this.setLastScrollTop()
    this.getScrollContainer().addEventListener(
      'scroll',
      this.setLastScrollTop,
      { passive: true }
    )

    this.showLabel = this.lastScrollTop < 5

    const updateCornerRegistrationByWindowWidth = () => {
      const widthLimit = 992
      const cornerElements = this.bottomRightCornerElements
      const name = FloatingCornerElementName.FACETS_BUTTON
      const nameAndCorner = {
        name,
        corner: Corner.BOTTOM_RIGHT
      }
      if (window.innerWidth >= widthLimit && cornerElements.has(name)) {
        this.removeCornerElement(nameAndCorner)
      } else if (window.innerWidth < widthLimit && !cornerElements.has(name)) {
        this.addCornerElement({
          ...nameAndCorner,
          componentInstance: this
        })
      }
    }
    updateCornerRegistrationByWindowWidth()
    window.addEventListener('resize', updateCornerRegistrationByWindowWidth)
    this.$once('hook:beforeDestroy', updateCornerRegistrationByWindowWidth)
  },
  beforeDestroy() {
    this.getScrollContainer().removeEventListener(
      'scroll',
      this.setLastScrollTop
    )
  },
  methods: {
    ...mapActions(PAGE_NS, ['addCornerElement']),
    ...mapMutations(PAGE_NS, {
      removeCornerElement: REMOVE_CORNER_ELEMENT
    }),
    setLastScrollTop() {
      const container = this.getScrollContainer()
      if (window instanceof container.constructor) {
        this.lastScrollTop =
          container.pageYOffset || document.documentElement.scrollTop
      } else {
        this.lastScrollTop = container.scrollTop
      }
    },
    handleFloatingButtonClick() {
      if (this.analyticsEventNamespace) {
        this.$analytics.recordEvent({
          namespace: this.analyticsEventNamespace,
          action: 'click'
        })
      }

      this.$emit('click')
    },
    getScrollContainer(): Element | Window {
      if (this.scrollContainer) {
        return this.scrollContainer
      }

      return window
    }
  }
})
