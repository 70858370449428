













import Vue, { PropOptions } from 'vue'
import { Option } from '~/models/shared/types'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'

export default Vue.extend({
  components: {
    MakeOptionWithLogoAndCount
  },
  props: {
    focused: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    option: {
      type: Object,
      required: true
    } as PropOptions<Option>,
    withMakeLogo: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: true
    }
  }
})
