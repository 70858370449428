







import Vue from 'vue'

export default Vue.extend({
  methods: {
    emitVisibilityChange(isVisible: boolean) {
      this.$emit('visibility-change', isVisible)
    }
  }
})
