import { injectable, container } from 'tsyringe'
import Vue, { VNodeData } from 'vue'
import { mergeData } from 'vue-functional-data-merge'
import { WrappedComponent, VNodeDataGetter } from '~/models/component'

@injectable()
export class WrapperComponentBuilder {
  private wrappedComponent: WrappedComponent | undefined
  private dataGetter: VNodeDataGetter = _data => ({})
  private functional_: boolean = true

  component(wrappedComponent: WrappedComponent): WrapperComponentBuilder {
    this.wrappedComponent = wrappedComponent
    return this
  }

  data(
    dataGetterOrObject: VNodeDataGetter | VNodeData
  ): WrapperComponentBuilder {
    this.dataGetter =
      typeof dataGetterOrObject === 'function'
        ? dataGetterOrObject
        : () => dataGetterOrObject
    return this
  }

  functional(functional: boolean = true): WrapperComponentBuilder {
    this.functional_ = functional
    return this
  }

  /**
   * Lacks return type due to complexity of ExtendedVue generics. Providing `any` in their place may break editor typing
   * for props or other VNodeData.
   */
  build() {
    const { wrappedComponent, dataGetter, functional_ } = this

    if (!wrappedComponent) {
      throw new Error('Can not build component wrapper without a component')
    }

    return Vue.extend({
      functional: functional_,
      render(createElement, { data, children }) {
        return createElement(
          wrappedComponent,
          mergeData(data, dataGetter(data)),
          children
        )
      }
    })
  }
}

/**
 * Factory
 */
export function wrapperComponent(
  wrappedComponent: WrappedComponent,
  dataGetterOrObject?: VNodeDataGetter | VNodeData
) {
  const builder = container
    .resolve(WrapperComponentBuilder)
    .component(wrappedComponent)

  dataGetterOrObject && builder.data(dataGetterOrObject)

  return builder.build()
}

export function wrapperComponentBuilder(
  wrappedComponent: WrappedComponent
): WrapperComponentBuilder {
  return container.resolve(WrapperComponentBuilder).component(wrappedComponent)
}
