








































































































































import { mapGetters, mapMutations, mapState } from 'vuex'
import GalleryRow from './rows/main/GalleryRow.vue'
import ListRow from './rows/main/ListRow.vue'
import { elementIsVisibleInViewport } from '~/utils/dom'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_NS } from '~/store/modules/shared/user/state'
import SellerRow from '~/components/car/classifieds/search/results/rows/main/SellerRow.vue'

import { SearchSellerRow } from '~/models/search/types'
import Footer from '~/components/car/classifieds/search/results/Footer.vue'
import Header from '~/components/car/classifieds/search/results/header/Header.vue'
import NoResultsFound from '~/components/car/classifieds/search/results/rows/NoResultsFound.vue'
import Bottom from '~/components/car/classifieds/search/results/Bottom.vue'
import RowsPending from '~/components/car/classifieds/search/results/rows/RowsPending.vue'
import RowsContainer from '~/components/car/classifieds/search/results/rows/main/RowsContainer.vue'
import { ViewType } from '~/models/shared/types'
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import RowAd from '~/components/car/classifieds/search/results/RowAd.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ClassifiedSearchActionsService from '~/services/search/ClassifiedSearchActionsService'
import { defineComponent } from '@nuxtjs/composition-api'
import { useSearchRowsAds } from '~/compositions/ad'
import { MODIFY_ROW_PARKED } from '~/store/modules/shared/classifieds/search/mutation-types'
import TaxiPermitsAlert from '~/components/car/classifieds/search/results/TaxiPermitsAlert.vue'

export default defineComponent({
  components: {
    TaxiPermitsAlert,
    RowAd,
    LazyHydrate,
    RowsPending,
    Bottom,
    AdminMassActions: () =>
      import(
        '~/components/car/classifieds/search/results/AdminMassActions.vue'
      ),
    MoreResultsButton: () =>
      import(
        '~/components/car/classifieds/search/results/MoreResultsButton.vue'
      ),
    MobileTopBar: () =>
      import('~/components/car/classifieds/search/mobile/MobileTopBar.vue'),
    Footer,
    Header,
    NoResultsFound,
    SellerRow,
    GalleryRow,
    ListRow,
    RowsContainer,
    PrivateSellersRecommendation: () =>
      import(
        '~/components/car/classifieds/search/results/rows/PrivateSellersRecommendation.vue'
      )
  },
  setup() {
    const { rowsAds, sellersAds } = useSearchRowsAds()
    return { rowsAds, sellersAds }
  },
  computed: {
    RowComponent() {
      switch (this.viewType) {
        case ViewType.GALLERY: {
          return GalleryRow
        }
        case ViewType.LIST:
        default: {
          return ListRow
        }
      }
    },
    ...mapDeps({
      classifiedSearchActionsService: ClassifiedSearchActionsService
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      rows: state => state.rows,
      sellerRows: state => state.sellerRows,
      loadingResults: state => state.loading.results,
      stats: state => state.stats,
      showAdminMassActions: state => state.config.settings.showAdminMassActions,
      viewType: state => state.viewType,
      moreResultsButton: state => state.moreResultsButton,
      rowsExtraQueryParams: state => state.config.settings.rowsExtraQueryParams,
      rowsTarget: state => state.config.settings.rowsTarget,
      showAddress: state => state.config.settings.showAddress,
      showModificationDate: state => state.config.settings.showModificationDate,
      showDisableButtons: state => state.config.settings.showDisableButtons,
      galleryLgPerRow: state => state.config.settings.galleryLgPerRow,
      searchId: state => state.searchId,
      isFiltersPage: state => state.config.settings.isFiltersPage
    }),
    ...mapGetters(USER_NS, {
      userIsAdmin: 'isAdmin',
      isPayingUser: 'isPayingUser'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inTaxisSearch: 'inTaxisSearch',
      inPlotSearch: 'inPlotSearch',
      inBoatsSearch: 'inBoatsSearch',
      viewTypeIsList: 'viewTypeIsList',
      showSellers: 'showSellers',
      adsKeyValues: 'getAdsKeyValues',
      shouldDisplayAds: 'shouldDisplayAds',
      currentPage: 'getResultsPaginationPage'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isMobile: 'isMobile'
    })
  },
  watch: {
    async searchId() {
      if (this.isPc && this.$refs.rowsContainer) {
        await this.$nextTick()
        const rowsContainerIsVisible = elementIsVisibleInViewport(
          this.$refs.rowsContainer?.$el
        )
        if (!rowsContainerIsVisible) {
          window.scroll(0, 0)
        }
      } else if (!this.isFiltersPage) {
        window.scroll(0, 0)
      }
    }
  },
  methods: {
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      modifyRowParked: MODIFY_ROW_PARKED
    }),
    sellerCollapseToggled(sellerId: number, collapseState: boolean) {
      ;(this.sellerRows || []).forEach((seller: SearchSellerRow) => {
        if (!collapseState || seller.id === sellerId) {
          return
        }
        const ref = this.$refs[`sellerRow${seller.id}`][0]
        ref && ref.closeCollapse()
      })
    }
  }
})
