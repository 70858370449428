



































import KeyFeatureIcon from '~/components/car/classifieds/view/partials/key-features/KeyFeatureIcon.vue'
import { ciCategory } from '~/icons/source/light/category'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { KeyFeatureIcon },
  props: {
    classifiedId: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number',
      required: true
    },
    keyFeatures: {
      type: Array,
      required: true
    },
    getKeyFeatureIcon: {
      type: Function,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    inFooter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    keyFeaturesStr() {
      return this.keyFeatures.map(feature => feature.value).join(', ')
    },
    categoryIcon() {
      return ciCategory
    }
  }
})
