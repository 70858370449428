









import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Icon } from '~/models/common/types'

export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true
    },
    focused: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    icon: {
      type: Object as PropType<Icon>,
      required: true
    }
  }
})
