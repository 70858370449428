











import { computed, defineComponent } from '@nuxtjs/composition-api'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { useNamespacedStore } from '~/compositions/store'

export default defineComponent({
  setup() {
    const { state: classifiedSearchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )

    const loadingFacets = computed(() => classifiedSearchState.loading.facets)

    return { loadingFacets }
  }
})
