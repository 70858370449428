








import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Position } from '~/models/app/position'

export default defineComponent({
  props: {
    position: {
      type: String as PropType<Position>,
      default: Position.TOP_LEFT
    },
    rotate: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const badgeClasses = computed(() => {
      const classes = []

      if (props.position === Position.RIGHT) {
        classes.push(
          'tw-left-auto tw-bottom-auto tw-top-auto tw-right-2 tw-m-auto tw-px-1.5 tw-py-0.5 tw-self-center tw-rounded'
        )
      } else {
        classes.push('tw-rounded-full tw-size-7')

        if (props.position === Position.TOP_LEFT) {
          classes.push('tw--top-3 tw--left-2')
        } else if (props.position === Position.TOP_RIGHT) {
          classes.push('tw--top-3 tw--right-2')
        } else if (props.position === Position.BOTTOM_RIGHT) {
          classes.push('tw--bottom-3 tw--right-2')
        } else if (props.position === Position.BOTTOM_LEFT) {
          classes.push('tw--bottom-3 tw--left-2')
        }
      }

      if (props.rotate) {
        classes.push('tw-rotate-[-20deg]')
      }

      return classes
    })

    return { badgeClasses }
  }
})
