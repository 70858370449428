
































































import {
  defineComponent,
  ref,
  computed,
  toRefs,
  PropType,
  onMounted,
  watch
} from '@nuxtjs/composition-api'
import { Trigger } from '~/models/app/trigger'
import { Position } from '~/models/app/position'
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight
} from '@fortawesome/free-solid-svg-icons'
import { useId } from '~/compositions/id'
import { Variant, VariantType } from '~/models/app/variant'

export default defineComponent({
  props: {
    text: { type: String, default: null },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.PRIMARY
    },
    position: {
      type: String as PropType<Position>,
      default: Position.BOTTOM_START
    },
    buttonClass: { type: String, default: null },
    itemsWrapperClass: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    noCaret: { type: Boolean, default: false },
    buttonSize: { type: String, default: null },
    containerClass: { type: String, default: null },
    triggers: { type: Array, default: () => [Trigger.CLICK] },
    loading: { type: Boolean, default: false }
  },

  setup(props, { emit, slots }) {
    const { disabled, position, containerClass } = toRefs(props)
    const activeItem = ref(-1)
    const dropdownTemplateRef = ref(null)
    const itemsWrapper = ref(null)
    const { createRandomId } = useId()
    const id = createRandomId()
    const menuItems = ref([])

    onMounted(() => {
      setMenuItems()
      watch(itemsWrapper, setMenuItems)
    })

    const setMenuItems = () => {
      menuItems.value = getMenuItems()
    }

    const handleKeydown = (e: KeyboardEvent) => {
      if (disabled.value) {
        return
      }
      switch (e.key) {
        case 'Enter':
          e.preventDefault()
          if (activeItem.value !== -1 && menuItems.value.length > 0) {
            const liElement = menuItems.value[activeItem.value]?.elm
            if (liElement?.click && liElement?.lastChild?.tagName !== 'A') {
              liElement.click()
            } else if (
              liElement?.lastChild?.click &&
              liElement?.lastChild?.tagName === 'A'
            ) {
              liElement.lastChild.click()
            }
          }

          break
        case 'ArrowUp':
          if (activeItem.value > 0) {
            activeItem.value = prevActiveItem(activeItem.value - 1)
            handleSetActive()
            scrollToItem()
          }
          e.preventDefault()
          break
        case 'ArrowDown':
          if (activeItem.value < menuItems.value.length - 1) {
            activeItem.value = nextActiveItem(activeItem.value + 1)
            handleSetActive()
            scrollToItem()
          }
          e.preventDefault()
          break
      }
    }

    const popperClass = computed(() => {
      if (hasCustomTriggerSlot.value) {
        return 'tw-w-auto ' + containerClass.value
      }
      return 'tw-w-full ' + containerClass.value
    })

    const icons = computed(() => ({
      caretDown: faCaretDown,
      caretLeft: faCaretLeft,
      caretRight: faCaretRight
    }))

    const caretIcon = computed(() => {
      if (position.value.match(/bottom/i)) {
        return icons.value.caretDown
      } else if (isPositionLeft.value) {
        return icons.value.caretLeft
      } else if (position.value.match(/right/i)) {
        return icons.value.caretRight
      }
    })

    const isPositionLeft = computed(() => position.value.match(/left/i))

    const getMenuItems = () => {
      if (!dropdownTemplateRef) {
        return []
      }
      const slots = ref(dropdownTemplateRef.value?.$slots)
      if (dropdownTemplateRef.value?.$slots) {
        const ul = slots.value.content.find(c => c.tag === 'ul')
        return ul.children.filter(
          c => c.componentOptions?.tag === 'CDropdownMenuItem'
        )
      }
      return []
    }

    const nextActiveItem = (index: number) => {
      const normalizedIndex = index < 0 ? 0 : index
      const i = menuItems.value
        .slice(normalizedIndex)
        .findIndex(c => !c.componentInstance?.disabled)
      return normalizedIndex + i
    }

    const prevActiveItem = index => {
      const indexes = []
      menuItems.value.slice().forEach((c, j) => {
        if (!c.componentInstance?.disabled && j <= index) {
          indexes.push(j)
        }
      })
      return Math.max(...indexes)
    }

    const handleSetActive = () => {
      menuItems.value.forEach((m, i) => {
        if (!m.componentInstance?.setFocusedStatus) {
          return
        }
        if (i === activeItem.value) {
          m.componentInstance.setFocusedStatus(true)
        } else {
          m.componentInstance.setFocusedStatus(false)
        }
      })
    }
    const scrollToItem = () => {
      const ul =
        dropdownTemplateRef.value?.$slots?.content[0]?.context?.$refs
          ?.itemsWrapper
      const hasUlScrollbar = ul.scrollHeight - ul.clientHeight > 0
      const currentItem = ul.children[activeItem.value]

      if (!hasUlScrollbar) {
        return
      }
      const scrollTop = ul.clientHeight - currentItem.offsetTop
      if (scrollTop <= 0) {
        ul.scrollTo(0, Math.abs(scrollTop) + currentItem.clientHeight)
      } else {
        ul.scrollTo(0, 0)
      }
    }
    const handleHidden = () => {
      activeItem.value = -1
      handleSetActive()
      emit('hidden')
    }

    const hasCustomTriggerSlot = computed(() => Boolean(slots.trigger))

    return {
      Trigger,
      Position,
      handleKeydown,
      dropdownTemplateRef,
      activeItem,
      handleHidden,
      icons,
      caretIcon,
      id,
      isPositionLeft,
      itemsWrapper,
      hasCustomTriggerSlot,
      popperClass
    }
  }
})
