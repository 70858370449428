








import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  inheritAttrs: true,
  components: {
    VCalendarDatepicker: () =>
      import('v-calendar/lib/components/date-picker.umd')
  },
  setup() {
    const calendarRef = ref()
    function focusDate(date: Date) {
      calendarRef.value.focusDate(date)
    }

    return { calendarRef, focusDate }
  }
})
