import { render, staticRenderFns } from "./PaidClassifiedBadge.vue?vue&type=template&id=c49bb5f2&scoped=true&"
import script from "./PaidClassifiedBadge.vue?vue&type=script&lang=ts&"
export * from "./PaidClassifiedBadge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c49bb5f2",
  null
  
)

export default component.exports