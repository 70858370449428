
























































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CAdmanAd from '~/components/shared/configurable/ad/CAdmanAd.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { ADS_NS } from '~/store/modules/shared/ads/state'
import { useSearchBottomAds } from '~/compositions/ad'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'
import CGoogleAd from '~/components/shared/configurable/ad/CGoogleAd.vue'

export default Vue.extend({
  components: { CGoogleAd, CPosting, CAdmanAd },
  setup() {
    const { fullAd, leftAd, rightAd } = useSearchBottomAds()
    return { fullAd, leftAd, rightAd }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      adsKeyValues: 'getAdsKeyValues',
      viewTypeIsList: 'viewTypeIsList',
      inXymaSearch: 'inXymaSearch',
      isJobsSearch: 'isJobsSearch'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    ...mapState(ADS_NS, {
      showAdmanAds: state => state.showAdmanAds
    }),
    showFullAd() {
      return this.inXymaSearch || this.isJobsSearch
    }
  }
})
