var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tw-px-2': !_vm.isQuickSearch }},[_c('div',{class:{
      'tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2': _vm.isQuickSearch,
      'labeled-rows-container': !_vm.isQuickSearch
    }},[(_vm.showMakes && !_vm.onlyRegistration)?_c('div',{class:{ 'tw-w-1/2': _vm.isQuickSearch }},[_c('label',{class:{ 'tw-text-base': _vm.isQuickSearch }},[_vm._v(_vm._s(_vm.isQuickSearch ? _vm.$t('make') : _vm.$t('vehicle make')))]),_vm._v(" "),_c('CCustomSelect',{attrs:{"variant":"outline","size":_vm.isQuickSearch ? 'md' : 'sm',"no-unselect":"","placeholder":_vm.$t('make'),"show-all-button":false,"header-title":_vm.$t('vehicle make'),"multi-select":true,"no-portal":_vm.noPortal,"options":_vm.allMakesOptions,"searchable":"","header-classes":_vm.isQuickSearch ? '!tw-border-grey-400' : '',"checkbox-group-divider-indices":[_vm.topMakes.length - 1],"show-dropdown-footer":""},on:{"change":_vm.handleMakesChange},scopedSlots:_vm._u([{key:"checkboxLabel",fn:function(ref){
    var option = ref.option;
return [_c('MakeOptionWithLogoAndCount',{attrs:{"id":option.value,"name":option.text,"count":option.count}})]}}],null,false,581073417),model:{value:(_vm.selectedMakesValues),callback:function ($$v) {_vm.selectedMakesValues=$$v},expression:"selectedMakesValues"}})],1):_vm._e(),_vm._v(" "),((_vm.showModels || _vm.isQuickSearch) && !_vm.onlyRegistration)?_c('div',{class:{ 'tw-w-1/2 !tw-mt-0': _vm.isQuickSearch }},[_c('label',{class:{ 'tw-text-base': _vm.isQuickSearch }},[_vm._v(_vm._s(_vm.isQuickSearch ? _vm.$t('model') : _vm.$t('vehicle model')))]),_vm._v(" "),(_vm.typedModels && _vm.typedModels.length > 0)?_c('CCustomOptionGroupsSelect',{attrs:{"option-groups":_vm.typedModelsOptionGroups,"options":_vm.allModelsOptions,"size":"sm","no-portal":_vm.noPortal,"header-title":_vm.$t('vehicle model'),"multi-select":"","searchable":"","show-dropdown-footer":""},on:{"change":_vm.handleModelsChange},model:{value:(_vm.selectedModelsValues),callback:function ($$v) {_vm.selectedModelsValues=$$v},expression:"selectedModelsValues"}}):_c('CCustomSelect',{attrs:{"variant":"outline","no-unselect":"","placeholder":_vm.$t('vehicle model'),"show-all-button":false,"multi-select":"","size":_vm.isQuickSearch ? 'md' : 'sm',"no-portal":_vm.noPortal,"options":_vm.allModelsOptions,"header-title":_vm.$t('vehicle model'),"header-classes":_vm.isQuickSearch ? '!tw-border-grey-400' : '',"searchable":"","show-dropdown-footer":""},on:{"change":_vm.handleModelsChange},model:{value:(_vm.selectedModelsValues),callback:function ($$v) {_vm.selectedModelsValues=$$v},expression:"selectedModelsValues"}})],1):_vm._e(),_vm._v(" "),(
        (_vm.showRegistration && !_vm.isQuickSearch) ||
          (_vm.isQuickSearch && _vm.onlyRegistration)
      )?_c('div',{staticClass:"tw-w-full"},[_c('label',{class:{ 'tw-text-base': _vm.isQuickSearch }},[_vm._v(_vm._s(_vm.isQuickSearch ? _vm.$t('registration') : _vm.$t('vehicle registration')))]),_vm._v(" "),_c('CFormSelect',{attrs:{"text-field":"n","value-field":"v","size":_vm.isQuickSearch ? 'md' : 'sm',"options":_vm.years,"title":_vm.$t('vehicle registration'),"roundness-class":_vm.isQuickSearch ? 'tw-rounded-lg' : 'tw-rounded',"border-classes":_vm.isQuickSearch ? '!tw-border-grey-400' : ''},on:{"change":_vm.handleRegistrationChange},model:{value:(_vm.selectedRegistration),callback:function ($$v) {_vm.selectedRegistration=$$v},expression:"selectedRegistration"}})],1):_vm._e(),_vm._v(" "),(!_vm.isQuickSearch)?_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-w-full"},[_c('CCheckbox',{on:{"change":_vm.handleStrictTextSearchChange},model:{value:(_vm.strictTextSearch),callback:function ($$v) {_vm.strictTextSearch=$$v},expression:"strictTextSearch"}},[_vm._v("\n        "+_vm._s(_vm.$t('search in description'))+"\n      ")]),_vm._v(" "),_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover.bottom.viewport",value:({
          popperClass: 'tw-max-w-xs',
          content: _vm.textSearchTooltipText
        }),expression:"{\n          popperClass: 'tw-max-w-xs',\n          content: textSearchTooltipText\n        }",modifiers:{"hover":true,"bottom":true,"viewport":true}}],staticClass:"tw-px-2 text-search-tooltip-container"},[_c('fa',{staticClass:"text-search-tooltip",attrs:{"icon":_vm.icons.infoCircle}})],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }