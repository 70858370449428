














import Vue, { PropType } from 'vue'
import { GoogleOrAdmanAd } from '~/models/ads'
import { mapGetters, mapState } from 'vuex'
import { ADS_NS } from '~/store/modules/shared/ads/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CAdmanAd from '~/components/shared/configurable/ad/CAdmanAd.vue'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'

export default Vue.extend({
  components: { CPosting, CAdmanAd },
  props: {
    ad: {
      type: Object as PropType<GoogleOrAdmanAd>,
      required: true
    }
  },
  computed: {
    ...mapState(ADS_NS, {
      showAdmanAds: state => state.showAdmanAds
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      adsKeyValues: 'getAdsKeyValues'
    })
  }
})
