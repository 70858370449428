
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  SET_LOADING_RESULTS,
  SET_PARAMS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import SearchService from '~/services/search/SearchService'

export default Vue.extend({
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      showMobileFacets: state => state.showMobileFacets
    })
  },
  mounted() {
    this.recordListViewsStats({ debounced: false })
    window.history.replaceState(
      { isInSearchPage: true },
      '',
      window.location.href
    )

    this.addEventListeners()
    this.$once('hook:beforeDestroy', this.removeEventListeners)
  },
  beforeDestroy() {
    this.removeEventListeners()
  },
  deactivated() {
    this.removeEventListeners()
    this.$once('hook:activated', this.addEventListeners)
  },
  methods: {
    addEventListeners() {
      window.addEventListener('popstate', this.handlePopState, false)
    },
    removeEventListeners() {
      if (this.showMobileFacets === true) {
        this.toggleMobileFacets() // this will close them
      }
      window.removeEventListener('popstate', this.handlePopState)
    },
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      search: 'search',
      recordListViewsStats: 'recordListViewsStats',
      toggleMobileFacets: 'toggleMobileFacets'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setLoadingResults: SET_LOADING_RESULTS,
      setParams: SET_PARAMS
    }),
    async handlePopState(event) {
      if (!event.state) {
        return
      }
      const { isInSearchPage, resetSearchState } = event.state
      if (!isInSearchPage) {
        if (resetSearchState) {
          window.history.replaceState(
            { isInSearchPage: true },
            '',
            window.location.href
          )
        }
        return
      }
      this.setLoadingResults(true)
      const { pathname, search } = event.target.location
      const params = await this.$dep(SearchService).getParams(pathname + search)
      this.setParams(params)
      this.search({
        pageView: true,
        updateUrl: false
      })
    }
  }
})
