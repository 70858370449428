
















































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CTreeSelect from '~/components/shared/configurable/form/select/tree/CTreeSelect.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import ExpandableHandlerContent from '~/components/car/classifieds/search/facets/handlers/ExpandableHandlerContent.vue'

export default Vue.extend({
  components: {
    CTreeSelect,
    ExpandableHandlerContent
  },
  extends: BaseSearchHandler,
  props: {
    isQuickSearch: {
      type: Boolean,
      default: false
    },
    isLocationSubHandler: {
      type: Boolean,
      default: false
    },
    hasLabel: {
      type: Boolean,
      default: true
    },
    isRentalQuickSearch: {
      type: Boolean,
      default: false
    },
    isProfessionalQuickSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    computedPlaceholder() {
      return this.isRentalQuickSearch
        ? this.$t('categories')
        : this.isQuickSearch
        ? this.$t('all')
        : null
    },
    isExpandable() {
      if (this.isQuickSearch) return false

      const { isSelected, searchables } = this
      return !isSelected && searchables && searchables.length > 11
    },
    ...mapState(CLASSIFIED_SEARCH_NS, {
      rootCategory: state => state.config.rootParams.category
    }),
    filteredSearchables() {
      const { searchables, isLocationSubHandler } = this

      if (isLocationSubHandler) return null
      const searchable = searchables.find(s => s.k)

      return searchable ? searchable.k : undefined
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch'
    }),
    onClear() {
      if (this.name === 'category') {
        this.emitParams([
          { name: this.defaultUrlArgName, value: [this.rootCategory] }
        ])
        return
      }
      this.selectedValues = []
      this.emitParams([
        { name: this.defaultUrlArgName, value: this.selectedValues }
      ])
    },
    onBackendValues(values: any) {
      this.selectedValues = values.selected
    },
    handleRedirectUrlClick(redirectUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl: redirectUrl
      })
    },
    treeChanged(newValues: any[]) {
      this.selectedValues = newValues
      this.emitParams([{ name: this.defaultUrlArgName, value: newValues }])
    }
  }
})
