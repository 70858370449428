







import Vue from 'vue'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
export default Vue.extend({
  props: {
    address: {
      type: [String, null],
      default: null,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    icons: {
      mapMarker: faMapMarkerAlt
    }
  })
})
