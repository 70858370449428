





















import Vue from 'vue'
import TreeSearchHandler from '~/components/car/classifieds/search/facets/handlers/TreeSearchHandler.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import SearchService from '~/services/search/SearchService'
import { Facet } from '~/models/search/types'
export default Vue.extend({
  components: {
    TreeSearchHandler,
    PostcodeDistanceSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/PostcodeDistanceSearchHandler'
      )
  },
  extends: BaseSearchHandler,
  computed: {
    postcodeFacet() {
      return this.getFacetByName('postcode')
    },
    ...mapDeps({ searchService: SearchService })
  },
  methods: {
    getBindings(handler: Facet) {
      return this.searchService.getFacetComponentBindings(handler)
    },
    onClear() {
      this.emitParams([
        {
          name: 'postcode',
          value: null
        },
        {
          name: 'country_code',
          value: null
        },
        {
          name: 'distance',
          value: null
        },
        {
          name: this.defaultUrlArgName,
          value: []
        },
        {
          name: 'rg',
          value: null
        }
      ])
    }
  }
})
