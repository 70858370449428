









































































































































import { mapGetters, mapActions, mapState } from 'vuex'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart'
import { APP_NS } from '~/store/modules/shared/app/state'
import { CategoryId } from '~/models/category/types'
import CCollapse from '~/components/shared/configurable/collapse/CCollapse.vue'
import CDropdownMenu from '~/components/shared/configurable/dropdown/CDropdownMenu.vue'
import CDropdownMenuItem from '~/components/shared/configurable/dropdown/CDropdownMenuItem.vue'
import { Position } from '~/models/app/position'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { CDropdownMenu, CDropdownMenuItem, CCollapse },
  props: {
    collapsable: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: true,
      recentSearches: [],
      isModalVisible: false,
      collapsed: false
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      currentCategoryIdGetter: 'getCurrentCategoryId',
      inPlotSearch: 'inPlotSearch'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isMapSearch: state => state.flags.isMapSearch
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    searchBucketService() {
      return this.$dep(SearchBucketService)
    },
    icons() {
      return {
        heart: faHeart,
        rHeart: farHeart
      }
    },
    position() {
      return Position
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch',
      toggleMobileFacets: 'toggleMobileFacets'
    }),
    handleShown() {
      this.$refs.modal.zIndex = 2002
    },
    handleShowSearches() {
      if (this.collapsable) {
        if (!this.collapsed) {
          this.fetchData()
        }
        this.collapsed = !this.collapsed
      } else {
        this.fetchData()
        this.isModalVisible = true
      }
      this.trackShowRecentSearchesClick()
    },
    async addToFavorites(id: string) {
      try {
        this.isLoading = true
        await this.searchBucketService.addSearchToFavorites(id)
        const recent = this.recentSearches.find(r => r.id === id)
        recent.isFavorite = true
        this.$snackbar.success(this.$t('added to favorites searches'))
      } catch (error) {
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    },
    async removeFromFavorites(id: string) {
      try {
        this.isLoading = true
        await this.searchBucketService.removeSearchFromFavorites(id)
        const recent = this.recentSearches.find(r => r.id === id)
        recent.isFavorite = false
        this.$snackbar.success(this.$t('removed from favorites searches'))
      } catch (error) {
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    },
    handleFavoriteChange(e: Event, isFavorite: boolean, id: string) {
      e.preventDefault()
      e.stopPropagation()
      if (isFavorite) {
        this.removeFromFavorites(id)
      } else {
        this.addToFavorites(id)
      }
    },
    handleClickDropdown() {
      this.fetchData()
      this.trackShowRecentSearchesClick()
    },
    trackShowRecentSearchesClick() {
      this.$analytics.recordEvent({
        namespace: 'n_recent_searches_button_clicked',
        action: 'click',
        label: 'show recent searches'
      })
    },
    handleClickRecent(seoUrl: string) {
      this.loadSeoUrlSearch({ seoUrl })
      this.toggleMobileFacets()
      this.isModalVisible = false
    },
    async fetchData() {
      const data = []
      this.isLoading = true
      try {
        const param =
          this.isMapSearch || this.isPlot || this.inPlotSearch
            ? CategoryId.PLOT
            : undefined
        const [favorites, recents] = await Promise.all([
          this.searchBucketService.getFavoriteSearches(param),
          this.searchBucketService.getRecentSearchesForCategory(param)
        ])
        recents.forEach(t => {
          data.push({ ...t, isFavorite: !!favorites.get(t.id) })
        })
        this.recentSearches = data
      } catch (error) {
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    }
  }
})
